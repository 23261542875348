exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-aboutus-1-new-tsx": () => import("./../../../src/pages/aboutus_1_new.tsx" /* webpackChunkName: "component---src-pages-aboutus-1-new-tsx" */),
  "component---src-pages-aboutus-tsx": () => import("./../../../src/pages/aboutus.tsx" /* webpackChunkName: "component---src-pages-aboutus-tsx" */),
  "component---src-pages-catalog-tsx": () => import("./../../../src/pages/catalog.tsx" /* webpackChunkName: "component---src-pages-catalog-tsx" */),
  "component---src-pages-contactus-tsx": () => import("./../../../src/pages/contactus.tsx" /* webpackChunkName: "component---src-pages-contactus-tsx" */),
  "component---src-pages-enquiry-tsx": () => import("./../../../src/pages/enquiry.tsx" /* webpackChunkName: "component---src-pages-enquiry-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-online-warranty-tsx": () => import("./../../../src/pages/online-warranty.tsx" /* webpackChunkName: "component---src-pages-online-warranty-tsx" */),
  "component---src-pages-pages-tsx": () => import("./../../../src/pages/pages.tsx" /* webpackChunkName: "component---src-pages-pages-tsx" */),
  "component---src-pages-payments-tsx": () => import("./../../../src/pages/payments.tsx" /* webpackChunkName: "component---src-pages-payments-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-termsandconditions-tsx": () => import("./../../../src/pages/termsandconditions.tsx" /* webpackChunkName: "component---src-pages-termsandconditions-tsx" */),
  "component---src-templates-faq-tsx": () => import("./../../../src/templates/faq.tsx" /* webpackChunkName: "component---src-templates-faq-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */)
}

